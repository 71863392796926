<template>
    <div>
        <PageHeader></PageHeader>
        <div class="inner d-flex align-center justify-center flex-column"><h1 class="title theme-gradient">404!</h1><h3 class="sub-title">{{$store.state.language.noPage.notFound}}</h3><span>{{$store.state.language.noPage.desc}}</span><div class="error-button"><a class="rn-button-style--2 btn-solid" href="/">{{$store.state.language.noPage.btn}}</a></div></div>

    </div>
</template>
<script>
import PageHeader from '@/components/PageHeader'
export default {
    name: 'Services',
    components: {
        PageHeader
    }
}
</script>

<style scoped>
    @keyframes fadeInUp {
        from {
            transform: translate3d(0, 40px, 0);
        }
        to {
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }

    .fade-in-up-leave-to {
        opacity: 0;
        /*transition: opacity 0.3s;*/
    }

    .fade-in-up-enter {
        opacity: 0;
        transform: translate3d(0, 10px, 0);
    }

    .fade-in-up-enter-to {
        opacity: 0;
        animation-duration: 0.7s;
        animation-fill-mode: both;
        animation-name: fadeInUp;
    }
    .inner{
        padding: 20px 0px 100px 0px !important
    }
   .inner h1.title {
        font-size: 130px !important;
        background: #f81f01 !important;
        background: -webkit-linear-gradient(145deg,#c3961e,#fabb11) !important;
        background: -webkit-linear-gradient(305deg,#c3961e,#fabb11) !important;
        background: linear-gradient(145deg,#c3961e,#fabb11) !important;
        background: -webkit-linear-gradient(349deg#c3961e#fabb11) !important;
        background: linear-gradient(-259deg,#c3961e#fabb11) !important;
        font-weight: 700;
        -webkit-background-clip: text !important;
        -webkit-text-fill-color: transparent !important;
        display: inline-block !important;
        margin-bottom: 0px;
        padding: 35px 0px;
    }
    .inner span{
        color: rgba(29,29,36,.75);
        font-size: 24px;
    }
    .inner .sub-title {
        margin-bottom: 24px;
        font-weight: 400;
        font-size: 48px;
        color: rgba(29,29,36,.75);
        line-height: 30px;
    }

    .inner .rn-button-style--2 {
        text-decoration: none;
        color: white;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 2px;
        border: 3px solid #fabb11;
        padding: 20px 40px;
        border-radius: 6px;
        display: inline-block;
        font-weight: 500;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        border: 3px solid #fabb11;
        margin-top: 30px;
        background: #fabb11;
    }
    .inner .rn-button-style--2:hover {
        color: #fabb11;
        background-color: transparent;
        transform: translateY(-5px);
    }
</style>